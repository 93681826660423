// Import our custom CSS
import '../scss/theme.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.main-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

}

navbarBehaviour();


jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 50) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );

    // $('.counter-carousel').slick({
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1
    // });

    $('.focus-carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: false,
        nextArrow: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });

    // Custom carousel nav
    $('.focus-navigation .carousel-prev').click(function (e) {
        e.preventDefault();
        $('.focus-carousel').slick('slickPrev');
    });

    $('.focus-navigation .carousel-next').click(function (e) {
        e.preventDefault();
        $('.focus-carousel').slick('slickNext');
    });

    // News carousel
    $('.news-carousel').slick({
        infinite: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        autoplaySpeed: 2000,
        dots: true,
        appendDots: $('.news-dots-container'),
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    appendDots: $('.news-dots-container-mobile'),
                }
            },
        ]
    });

    // Video carousel
    $('.section-video-container').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    // Machine carousel
    $('.machine-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        dots: false,
    });

    // Impianto gallery carousel
    $('.impianto-gallery-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    // Machine impianto archive
    const impiantoSlider = $('.impianto-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        dots: true,
    });

    // Custom carousel nav
    $('.single-impianto-slider .carousel-prev').click(function (e) {
        e.preventDefault();
        $('.impianto-slider').slick('slickPrev');
    });

    $('.single-impianto-slider .carousel-next').click(function (e) {
        e.preventDefault();
        $('.impianto-slider').slick('slickNext');
    });

    // Custom carousel nav
    $('.news-navigation .carousel-prev').click(function (e) {
        e.preventDefault();
        $('.news-carousel').slick('slickPrev');
    });

    $('.news-navigation .carousel-next').click(function (e) {
        e.preventDefault();
        $('.news-carousel').slick('slickNext');
    });

    // Mega menu modal
    $('.mega-menu-modal a').click(function (e) {
        e.preventDefault();
        let modal_id = $(this).attr("href");

        $('.modal.show').modal('hide');

        $('.site-header.fixed-top').addClass('mega-menu-open');
        $(modal_id).modal('toggle');
    });

    $(".modal.modal-mega-menu").on('hidden.bs.modal', function () {
        if ($(".modal.modal-mega-menu.show").length === 0) {
            $('.site-header.fixed-top').removeClass('mega-menu-open');
        }

        $('.mega-menu-sub').each(function(){
            $(this).html('');
        });
    });

    $('.mega-menu-navigation').each(function () {
        let id_menu = $(this).attr('id');
        $(this).find('a').hover(function () {
            $('#mega-menu-sub-'+id_menu).html($(this).parent().find('.sub-menu').clone());
        })
    });

    $('.features-title').click(function(){
        if($(window).width() <= 992) {
            $(this).next().find('.features-accordion').slideToggle();
        }
    });


    // Ajax filter
    // Gestisci il clic sui pulsanti dei filtri
    $('.filter-button').on('click', function() {
        var $this = $(this);

        // Gestisci la classe active-filter solo per i pulsanti dei filtri dello stesso tipo
        if ($this.closest('.material-filter').length) {
            $('.material-filter .filter-button').removeClass('active-filter');
        } else if ($this.closest('.application-filter').length) {
            $('.application-filter .filter-button').removeClass('active-filter');
        }

        $this.addClass('active-filter');

        // Ottieni l'ID del materiale e dell'applicazione dai pulsanti attivi
        var materialeId = $('.material-filter .active-filter').data('materiale') || 0;
        var applicazioneId = $('.application-filter .active-filter').data('applicazione') || 0;

        // Invia la richiesta AJAX
        var data = {
            action: 'filter_posts',
            materiale: materialeId,
            applicazione: applicazioneId,
            security: ajax_object.ajax_nonce // Aggiungi il nonce qui
        };

        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: data,
            success: function(response) {
                $('#machine-container-filter').html(response);
            }
        });
    });

    $('#machine-category-filter .item-button').on('click', function(){
        var $this = $(this);
        var idTipologia = 0;

        $('#machine-category-filter li.item-button').removeClass('item-active');
        $this.addClass('item-active');

        idTipologia = $('#machine-category-filter .item-button.item-active').data('typology');

        var data = {
            action: 'filter_machine_category',
            tipologia: parseInt(idTipologia),
            security: ajax_object.ajax_nonce // Aggiungi il nonce qui
        };

        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: data,
            success: function(response) {
                $('#machine-list-container').html(response);
            }
        });

    });

    // Machine impianto archive
    $('.machine-category-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.machine-category-select .form-select').on('change', function (){
       var idTipologia = $(this).val();

        var data = {
            action: 'filter_machine_category_mobile',
            tipologia: parseInt(idTipologia),
            security: ajax_object.ajax_nonce // Aggiungi il nonce qui
        };

        $('.machine-category-carousel').height($('.machine-category-carousel').outerHeight(true)).slick('unslick').html('');

        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: data,
            success: function(response) {
                $('#machine-category-carousel').html(response);
                $('.machine-category-carousel').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: true,
                    autoplaySpeed: 2000,
                    autoplay: true,
                    prevArrow: false,
                    nextArrow: false,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });

                $('#machine-category-carousel').height('');
            }
        });

    });

}); // jQuery End

document.addEventListener('DOMContentLoaded', function () {
    var scrollInit = window.scrollY;

    if (scrollInit > 50) {
        // Controlla se la pagina è stata già scrollata di 50px dopo il caricamento
        document.querySelector('#header_three .logo').classList.remove('animate-logo');
        // Esegui qui le azioni necessarie dopo lo scroll di 50px subito dopo il caricamento
        document.querySelector('body').classList.add('is-scrolled');
    }

    window.addEventListener('scroll', function () {
        var scrollPosition = window.scrollY;
        var element = document.querySelector('#header_three .logo'); // Sostituisci 'tuoElemento' con l'id del tuo elemento

        if (scrollPosition > 50) {
            element.classList.remove('animate-logo'); // Sostituisci 'classeDaRimuovere' con la classe che desideri rimuovere
        } else {
            element.classList.add('animate-logo'); // Sostituisci 'classeDaRimuovere' con la classe che desideri aggiungere
        }
    });
});
